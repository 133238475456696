










import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import Project from '@/shared/modules/project/models/project.model'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { CompanyRoutes } from '@/shared/router/company'
import Can from '@/shared/components/Can.vue'
import permissions from '@/shared/helpers/permissions.helper'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import ProjectService from '@/shared/modules/project/project.service'
import DataTableFilter, { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import ProjectLocation from '@/shared/modules/project/models/project-location.model'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import DataTableHeaderTypes from '@/shared/classes/components/data-table/data-table-header-types'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import { RoleLevel } from '@/shared/modules/role/role.model'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import router from '@/shared/router'
import can from '@/shared/helpers/can.helper'
import toggleablePermissions from '@/shared/helpers/toggleable-permissions.helper'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import ShowAction from '@/shared/classes/components/data-table/default-actions/show-action'
import calculateBalanceColor from '@/shared/helpers/calculate-balance-color'

@Component({
    components: { Can, DataTable, PageHeader },
    methods: { __ }
  })
  export default class ProjectsIndex extends Vue {
    permissions = permissions
    table: DataTableBase|null = null

    created() {
      this.table = new DataTableBase()
        .setModel(Project)
        .setEndpoint(`company/${ this.company.slug }/projects`)
        .setDoubleClick(this.editProject)
        .setHeaders([
          new DataTableHeader()
            .setType(DataTableHeaderTypes.textLink)
            .setKey('name')
            .setText(__('company.views.projects.index.table.columns.name'))
            .setMeta({
              getRawLocation: (project: Project) => ({
                path: `${ this.$router.currentRoute.path }/${ project.uuid }`
              })
            }),
          new DataTableHeader()
            .setKey('readable_address')
            .setEntryKey(`readableAddress`)
            .setText(__('company.views.projects.index.table.columns.address')),
          new DataTableHeader()
            .setKey('location.country.name')
            .setSortable(false)
            .setText(__('company.views.projects.index.table.columns.country')),
          new DataTableHeader()
            .setKey('client.full_name')
            .setEntryKey('client.fullName')
            .setSortable(false)
            .setText(__('company.views.projects.index.table.columns.client')),
          new DataTableHeader()
            .setKey('responsible.full_name')
            .setEntryKey('responsible.fullName')
            .setSortable(false)
            .setText(__('company.views.projects.index.table.columns.responsible')),
          new DataTableHeader()
            .setKey('work_start_at')
            .setEntryKey('workStartAt')
            .setText(__('company.views.projects.index.table.columns.work-start-at')),
          new DataTableHeader()
            .setKey('work_end_at')
            .setEntryKey('workEndAt')
            .setText(__('company.views.projects.index.table.columns.work-end-at')),
          new DataTableHeader()
            .setKey('budget.estimated_hours')
            .setEntryKey('budget.estimatedHours')
            .setText(__('company.views.projects.index.table.columns.planned-hours'))
            .setVisible(can([ toggleablePermissions.projects.plannedHours ])),
          new DataTableHeader()
            .setKey('budget.used_without_extra_hours')
            .setEntryKey('budget.usedWithoutExtraHours')
            .setText(__('company.views.projects.index.table.columns.used-hours'))
            .setVisible(can([ toggleablePermissions.projects.usedHours ])),
          new DataTableHeader()
            .setKey('budget')
            .setText(__('company.views.projects.index.table.columns.balance'))
            .setValueParser((value: any) => `${ value.balance.hours } ${ __('company.views.projects.index.table.columns.balance-hours') }/${ value.balance.budget }`)
            .setStyleParser((value: any) => ({
                container: {
                  color: calculateBalanceColor(value),
                  fontWeight: 'bold',
                }
              }))
            .setVisible(can([ toggleablePermissions.projects.balance ])),
          new DataTableHeader()
            .setKey('budget.agreed_price')
            .setEntryKey('budget.agreedPrice')
            .setText(__('company.views.projects.index.table.columns.budget'))
            .setVisible(can([ toggleablePermissions.projects.budget ])),
          new DataTableHeader()
            .setKey('budget.used_extra_hours')
            .setEntryKey('budget.usedExtraHours')
            .setText(__('company.views.projects.index.table.columns.extra-hours'))
            .setVisible(can([ toggleablePermissions.projects.usedExtraHours ])),
          new DataTableHeader()
            .setKey('status.name')
            .setHasDot(true)
            .setStyleParser((value: any, item: Project) => ({
              dot: {
                backgroundColor: item.status.meta.color,
              }
            }))
            .setText(__('company.views.projects.index.table.columns.status'))
        ])
        .setFilters([
          new DataTableFilter()
            .setOperator(FilterOperators.in)
            .setKeyIsPrimary()
            .setField(
              new SelectField()
                .isMultiple()
                .setKey('status_uuid')
                .setTitle(__('company.views.projects.index.table.filters.status'))
                .loadItems({ endpoint: `statuses/model/${ Project.ALIAS }`, value: 'uuid', title: 'name', perPage: 20 })
            ),
          new DataTableFilter()
            .setOperator(FilterOperators.equals)
            .setKeyIsPrimary()
            .setField(
              new SearchableField()
                .setKey('location.country.uuid')
                .setTitle(__('company.views.projects.index.table.filters.country'))
                .loadItems({ endpoint: '/countries', value: 'uuid', title: 'name', perPage: 20 })
            ),
          new DataTableFilter()
            .setOperator(FilterOperators.equals)
            .setKeyIsPrimary()
            .setField(
              new SearchableField()
                .setKey('client.uuid')
                .setTitle(__('company.views.projects.index.table.filters.client'))
                .loadItems({ endpoint: `/company/${ this.company.slug }/clients`, value: 'uuid', title: 'full_name', perPage: 20 })
            ),
          new DataTableFilter()
            .setKeyIsPrimary()
            .setField(
              new Field()
                .setType(FieldTypes.datePicker)
                .setKey('work_start_at')
                .setTitle(__('company.views.projects.index.table.filters.work-start-at'))
            ),
          new DataTableFilter()
            .setKeyIsPrimary()
            .setField(
              new Field()
                .setType(FieldTypes.datePicker)
                .setKey('work_end_at')
                .setTitle(__('company.views.projects.index.table.filters.work-end-at'))
            ),
        ])
        .setActions([
          new DataTableAction()
            .setPermissions([
              permissions.company.projects.copy,
            ])
            .setIcon('mdi-content-copy')
            .setTitle(__('company.views.projects.index.table.actions.copy.title'))
            .setAction(async (item: Project) => {
              const project  = await ProjectService.copy(this.company, item.uuid)

              await this.$store.dispatch(GlobalActions.showSnackBar, {
                type: SnackBarTypes.success,
                message: __('company.views.projects.index.table.actions.copy.on-success')
              })

              this.table && this.table.addItem(project)
            }),
          new ShowAction(),
          new EditAction()
            .setPermissions([
              permissions.company.projects.show,
              permissions.company.projects.update,
            ]),
          new DeleteAction()
            .setPermissions([
              permissions.company.projects.delete,
            ])
            .setAction((item: Project) => this.$store.dispatch(GlobalActions.showDialog, {
              show: true,
              component: AreYouSureDialog,
              meta: {
                title: __('company.views.projects.index.table.actions.delete.title'),
                text: __('company.views.projects.index.table.actions.delete.text', { name: item.name }),
                onYes: () => ProjectService.destroy(this.company.slug, item.uuid)
                  .then(() => this.table && this.table.removeItem(item))
              }
            })),
        ])
    }

    navigateToCreate(): void {
      this.$router.push({ name: CompanyRoutes.projectsCreate })
    }

    editProject(item: Project): void {
      if (this.user.hasDynamicPermissions([permissions.company.projects.show, permissions.company.projects.update])) {
        this.$router.push({ path: `${ router.currentRoute.path }/${ item.uuid }/edit`})
      }
    }

    get company(): Company {
      return this.$store.getters[CompanyGetters.getCompany]
    }

    get user(): User {
      return this.$store.getters[AuthGetters.getUser]
    }
  }
